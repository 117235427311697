import {
  SchoolClassSyllabusChaptersModel,
  SchoolClassSyllabusChaptersTopicContentModel,
  SchoolClassSyllabusChaptersTopicModel,
  SchoolClassSyllabusModel,
} from '../shared/models/school-class-syllabus.model';
import { SchoolStudentAttendanceModel } from '../shared/models/school-student-attendance.model';
import { SchoolTeacherAttendanceModel } from '../shared/models/school-teacher-attendance.model';
import { SchoolTimetableWithDays } from '../shared/models/school-timetable.model';
import { SchoolModelCurriculum } from '../shared/models/school.model';
import { SchoolStudentAssessmentModel } from './../shared/models/school-student-assessment.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  gradeMapping: {
    [key: string]: number;
  } = {
    nursary: 0,
    nursery: 0,
    lkg: 1,
    ukg: 2,
    '1': 3,
    '2': 4,
    '3': 5,
    '4': 6,
    '5': 7,
    '6': 8,
    '7': 9,
    '8': 10,
    '9': 11,
    '10': 12,
    '11': 13,
    '12': 14,
  };

  removeDuplicates<T>(array: T[], key: keyof T): T[] {
    const uniqueSet = new Set();
    return array.filter((item) => {
      const keyValue = item[key];
      if (uniqueSet.has(keyValue)) {
        return false;
      } else {
        uniqueSet.add(keyValue);
        return true;
      }
    });
  }

  overallSyllabusCompletion(syllabus: SchoolClassSyllabusModel[]) {
    let totalChapters = 0;
    let completedChapters = 0;
    syllabus.forEach((classSyllabus) => {
      classSyllabus.chapters.forEach((chapter) => {
        totalChapters++;
        if (chapter.completed) {
          completedChapters++;
        }
      });
    });
    return totalChapters > 0
      ? Math.ceil((completedChapters / totalChapters) * 100)
      : 0;
  }

  subjectSyllabusCompletion(syllabus: SchoolClassSyllabusModel) {
    let totalChapters = 0;
    let completedChapters = 0;
    syllabus.chapters.forEach((chapter) => {
      totalChapters++;
      if (chapter.completed) {
        completedChapters++;
      }
    });
    return totalChapters > 0
      ? Math.ceil((completedChapters / totalChapters) * 100)
      : 0;
  }

  subjectWiseContentCasted(syllabus: SchoolClassSyllabusModel) {
    let contentsCasted = 0;
    syllabus.chapters.forEach((chapter) => {
      chapter.topics.forEach((topic) => {
        topic.contents.forEach((content) => {
          if (content.casted) {
            contentsCasted++;
          }
        });
      });
    });
    return contentsCasted;
  }

  calculatePerformance(assessments: SchoolStudentAssessmentModel[]) {
    let scored_marks = 0,
      total_marks = 0;
    if (assessments.length) {
      assessments.forEach((assessment: SchoolStudentAssessmentModel) => {
        let scored = assessment.scored_marks;
        let total = assessment.total_marks;

        if (scored < 0) {
          scored = 0;
        }
        if (total < 0) {
          total = 0;
        }
        if (scored > total) {
          scored = total;
        }
        scored_marks += scored;
        total_marks += total;
      });
      return Math.ceil((scored_marks * 100) / total_marks);
    } else {
      return -1;
    }
  }

  calculateAssessmentsTaken(assessments: SchoolStudentAssessmentModel[]) {
    if (assessments.length) {
      let uniqueQsets = [...new Set(assessments.map((a) => a.question_set))];
      return uniqueQsets.length;
    }
    return -1;
  }

  checkAttendanceToday(
    attendanceArray:
      | SchoolStudentAttendanceModel[]
      | SchoolTeacherAttendanceModel[]
  ) {
    let today = new Date();
    let todayAttDoc = attendanceArray.find(
      (att) =>
        att.attendance_date.day == today.getDate() &&
        att.attendance_date.month == today.getMonth() + 1 &&
        att.attendance_date.year == today.getFullYear()
    );
    return !!todayAttDoc ? todayAttDoc.present : -1;
  }

  calulateAttendance(
    attendanceArray:
      | SchoolStudentAttendanceModel[]
      | SchoolTeacherAttendanceModel[]
  ) {
    if (attendanceArray.length) {
      let presentArray = attendanceArray.filter((att: any) => !!att.present);
      if (presentArray.length) {
        return Math.ceil((presentArray.length * 100) / attendanceArray.length);
      } else {
        return 0;
      }
    } else {
      return -1;
    }
  }

  getSalutation() {
    const currentHour = new Date().getHours();
    let salutation = '';

    if (currentHour >= 5 && currentHour < 12) {
      salutation = `Good Morning,`;
    } else if (currentHour >= 12 && currentHour < 16) {
      salutation = `Good Afternoon,`;
    } else {
      salutation = `Good Evening,`;
    }

    return salutation;
  }

  toTitleCase(str: string): string {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the array back into a single string
  }

  sortClasses(classes: string[], dir: 'asc' | 'desc'): string[] {
    return classes.sort((a, b) => {
      // Remove any extra spaces and split into grade and suffix
      const [gradeA, suffixA] = a.trim().split(/ +/);
      const [gradeB, suffixB] = b.trim().split(/ +/);

      // Normalize the grades
      const normalizedGradeA = gradeA.toLowerCase();
      const normalizedGradeB = gradeB.toLowerCase();

      // Compare the grades using the gradeMapping object
      const gradeComparison =
        (this.gradeMapping[normalizedGradeA] ?? -1) -
        (this.gradeMapping[normalizedGradeB] ?? -1);

      // If the grades are the same, compare alphabetically by the suffix
      if (gradeComparison === 0) {
        return dir === 'asc'
          ? suffixA.localeCompare(suffixB)
          : suffixB.localeCompare(suffixA);
      }

      // Return the result based on the sorting direction
      return dir === 'asc' ? gradeComparison : -gradeComparison;
    });
  }

  findMissingElements(a: string[], b: string[]): string[] {
    const missingElements: string[] = [];

    // Check if every element of `a` is in `b`
    const allInB = a.every((elem) => b.includes(elem));

    if (allInB) {
      return missingElements; // Return an empty array if all elements of `a` are in `b`
    }

    // Find elements in `b` that are not in `a`
    b.forEach((elem) => {
      if (!a.includes(elem)) {
        missingElements.push(elem);
      }
    });

    return missingElements;
  }

  getContentsCastedToday(syllabus: SchoolClassSyllabusModel[]): number {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset to midnight for comparison

    let castedTodayCount = 0;

    syllabus.forEach((syllabusItem) => {
      syllabusItem.chapters.forEach((chapter) => {
        chapter.topics.forEach((topic) => {
          topic.contents.forEach((content) => {
            const castedToday = content.casted_dates.filter((castedDate) => {
              const castedDateOnly = new Date(castedDate);
              castedDateOnly.setHours(0, 0, 0, 0); // Compare by date only
              return castedDateOnly.getTime() === today.getTime();
            });

            // Increment the count for contents casted today
            castedTodayCount += castedToday.length;
          });
        });
      });
    });

    return castedTodayCount;
  }

  getStrengthPresentToday(
    attendanceArray:
      | SchoolStudentAttendanceModel[]
      | SchoolTeacherAttendanceModel[]
  ) {
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth() + 1; // Months are 0-based in JS Date
    const todayYear = today.getFullYear();

    let presentCount = 0;

    attendanceArray.forEach((record) => {
      const { day, month, year } = record.attendance_date;

      // Check if the attendance date is today and the student is present
      if (
        day === todayDay &&
        month === todayMonth &&
        year === todayYear &&
        record.present
      ) {
        presentCount++;
      }
    });

    return presentCount;
  }

  getTodayTaughtTopicsCount(syllabusArray: SchoolClassSyllabusModel[]): number {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to midnight for date-only comparison

    let topicsTaughtTodayCount = 0;

    syllabusArray.forEach((syllabus) => {
      syllabus.chapters.forEach((chapter) => {
        chapter.topics.forEach((topic) => {
          if (topic.completed && topic.completed_timestamp) {
            const topicDate = new Date(topic.completed_timestamp);
            topicDate.setHours(0, 0, 0, 0);
            if (topicDate.getTime() === today.getTime()) {
              topicsTaughtTodayCount++;
            }
          }
        });
      });
    });

    return topicsTaughtTodayCount;
  }

  getTodayUniqueAssessmentCount(
    assessments: SchoolStudentAssessmentModel[]
  ): number {
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JavaScript
    const currentYear = today.getFullYear();

    const uniqueQuestionSets = new Set<string>();

    assessments.forEach((assessment) => {
      const { day, month, year } = assessment.assessment_date;
      if (
        day === currentDay &&
        month === currentMonth &&
        year === currentYear
      ) {
        uniqueQuestionSets.add(assessment.question_set);
      }
    });

    return uniqueQuestionSets.size;
  }

  getTodayPeriods(timetableArray: SchoolTimetableWithDays[]) {
    const today = new Date();
    const todayDayOfWeek = today
      .toLocaleDateString('en-US', { weekday: 'long' })
      .toLowerCase();

    return timetableArray.reduce((total, timetableWithDays) => {
      const daySchedule = timetableWithDays.days[todayDayOfWeek];
      if (daySchedule) {
        const validPeriods = daySchedule.periods.filter(
          (period) =>
            ![
              'assembly',
              'lunch',
              'co-curricular activities',
              'short break',
            ].includes(period.type)
        );
        total += validPeriods.length;
      }
      return total;
    }, 0);
  }

  returnPhoneNumber(input: string): { telLink: string; phone: number } | null {
    // Remove non-digit characters, except the leading '+'
    const digitsOnly = input.replace(/[^\d+]/g, '');

    // Remove the '+' if it's there
    const normalizedInput = digitsOnly.startsWith('+')
      ? digitsOnly.slice(1)
      : digitsOnly;

    if (normalizedInput.startsWith('91') && normalizedInput.length === 12) {
      return {
        telLink: `tel:+91${normalizedInput.slice(2)}`,
        phone: +normalizedInput.slice(2),
      };
    } else if (normalizedInput.length === 10) {
      return { telLink: `tel:+91${normalizedInput}`, phone: +normalizedInput };
    } else {
      return null;
    }
  }

  returnCurriculum(curriculumArray: SchoolModelCurriculum[]) {
    return curriculumArray.reduce(
      (acc, curr) => {
        // Check for the lowest start year or if start year is the same, the lowest start month
        if (
          curr.start_year < acc.start_year ||
          (curr.start_year === acc.start_year &&
            curr.start_month < acc.start_month)
        ) {
          acc.start_year = curr.start_year;
          acc.start_month = curr.start_month;
        }

        // Check for the highest end year or if end year is the same, the highest end month
        if (
          curr.end_year > acc.end_year ||
          (curr.end_year === acc.end_year && curr.end_month > acc.end_month)
        ) {
          acc.end_year = curr.end_year;
          acc.end_month = curr.end_month;
        }

        return acc;
      },
      {
        start_month: Infinity,
        start_year: Infinity,
        end_month: -Infinity,
        end_year: -Infinity,
      }
    );
  }

  isCompletedDateInRange(
    completedDate: Date,
    dateRange?: { startDate: Date; endDate: Date }
  ): boolean {
    if (
      !completedDate ||
      !dateRange ||
      !dateRange.startDate ||
      !dateRange.endDate
    ) {
      return true; // No dateRange or invalid completedDate means "always true"
    }

    const completedTime = completedDate.getTime();
    const startTime = dateRange.startDate.getTime();
    const endTime = dateRange.endDate.getTime();

    return completedTime >= startTime && completedTime <= endTime;
  }

  calculateCastedHours(
    syllabusArray: SchoolClassSyllabusModel[],
    dateRange?: { startDate: Date; endDate: Date }
  ): number {
    if (!syllabusArray?.length) {
      return 0; // Return 0 if syllabusArray is empty or null/undefined
    }

    let completedTopics = 0;
    let castedContents = 0;

    syllabusArray.forEach((syllabus: SchoolClassSyllabusModel) => {
      syllabus?.chapters?.forEach(
        (chapter: SchoolClassSyllabusChaptersModel) => {
          chapter?.topics?.forEach(
            (topic: SchoolClassSyllabusChaptersTopicModel) => {
              if (
                topic?.completed &&
                this.isCompletedDateInRange(
                  topic.completed_timestamp as Date,
                  dateRange
                )
              ) {
                completedTopics++;

                topic?.contents?.forEach(
                  (content: SchoolClassSyllabusChaptersTopicContentModel) => {
                    if (
                      content?.casted &&
                      content?.casted_dates?.some((date: Date) =>
                        this.isCompletedDateInRange(date, dateRange)
                      )
                    ) {
                      castedContents++;
                    }
                  }
                );
              }
            }
          );
        }
      );
    });

    // const totalMinutes = Math.max(completedTopics, castedContents) * 30; // 30 minutes per topic/content
    // return totalMinutes / 60; // Return hours
    return (Math.max(completedTopics, castedContents) * 0.5);
  }
}
